import "../jason.css";
import styled from "@emotion/styled";

function Header() {
  return (
    <HeaderDiv>
      <Link href="/">Home</Link>
      <Spacer />
      <Link href="https://www.hyperwriteai.com" target="_blank">
        HyperWrite
      </Link>
      {/* <Spacer />
      <Link href="/aijason">AI Jason</Link> */}
    </HeaderDiv>
  );
}

export default Header;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Link = styled.a`
  margin: 4px 10px;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  &:hover {
    color: #666666;
  }
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const Spacer = styled.span`
  height: 18px;
  border-left: 1px solid #333333;
  @media only screen and (max-width: 768px) {
    height: 16px;
  }
`;
