import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { FaVideo, FaQuestion, FaImage } from "react-icons/fa";
import OpenAI from "openai";

import SamplePage from "../Components/lookupsamplepage.jpeg";

function LookUp() {
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true,
  });

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (password === "lori") {
      setAuthenticated(true);
    } else {
      alert("Incorrect password");
    }
  };

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleQuestionSubmit = async () => {
    setAnswer("");
    setLoading(true);
    const chatCompletion = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            "You are a science tutor teaching an 8 year old about solar eclipses. Answer their questions, even if they're not directly related to solar eclipses but are about science or space. If a question is unrelated, guide the conversation back to solar eclipses. Keep responses clear, short, and simple. Don't ask factual questions, but feel free to share your excitement about the topic and encourage the student to ask more questions or think about the topic in a new way.",
        },
        { role: "user", content: question },
      ],
      model: "gpt-3.5-turbo",
      // model: "gpt-4-turbo-preview",
    });

    setAnswer(chatCompletion.choices[0].message.content);
    setLoading(false);
  };

  const sampleQuestions = [
    "Why do we wear eclipse glasses?",
    "How hot is the sun?",
    "What is a solar eclipse?",
  ];

  window.speechSynthesis.onvoiceschanged = function () {
    const voices = window.speechSynthesis.getVoices();
  };

  const handleWordClick = (word) => {
    let utterance = new SpeechSynthesisUtterance(word);
    let voices = window.speechSynthesis.getVoices();
    utterance.voice = voices[49];
    window.speechSynthesis.speak(utterance);
  };

  if (!authenticated) {
    return (
      <div>
        <Input
          style={{ width: "100px" }}
          value={password}
          onChange={handlePasswordChange}
        />
        <Button onClick={handleLogin}>→</Button>
      </div>
    );
  }

  return (
    <Container>
      <LeftContainer>
        <h1>Look Up!</h1>
        <ImageContainer>
          <PageImage src={SamplePage} alt="Sample Page" />
          <VideoIconContainer
            onClick={() =>
              window.open(
                "https://svs.gsfc.nasa.gov/14509/#media_group_373271",
                "_blank"
              )
            }
          >
            <FaVideo size="1.4em" />
          </VideoIconContainer>
          <ImageIconContainer
            onClick={() =>
              window.open("https://science.nasa.gov/gallery/the-sun/", "_blank")
            }
          >
            <FaImage size="1.4em" />
          </ImageIconContainer>
          <QuestionIconContainer
            onClick={() =>
              window.open(
                "https://science.nasa.gov/eclipses/future-eclipses/eclipse-2024/safety/#:~:text=Except%20during%20the%20brief%20total%20phase%20of%20a%20total%20solar%20eclipse%2C%20when%20the%20Moon%20completely%20blocks%20the%20Sun%E2%80%99s%20bright%20face%2C%20it%20is%20not%20safe%20to%20look%20directly%20at%20the%20Sun%20without%20specialized%20eye%20protection%20for%20solar%20viewing.",
                "_blank"
              )
            }
          >
            <FaQuestion size="1.4em" />
          </QuestionIconContainer>
        </ImageContainer>
        <PageText>
          "Put your{" "}
          <span
            onClick={() => handleWordClick("eclipse")}
            className="highlight tooltip"
            data-tooltip="eclipse"
          >
            eclipse
          </span>{" "}
          <span
            onClick={() => handleWordClick("glasses")}
            className="highlight tooltip"
            data-tooltip="anteojos
            "
          >
            glasses
          </span>{" "}
          on," I say with care.
          <br />
          "They {""}
          <span
            onClick={() => handleWordClick("protect")}
            className="highlight tooltip"
            data-tooltip="protegen"
          >
            protect
          </span>{" "}
          your eyes to see this sight so rare."
        </PageText>
      </LeftContainer>
      <RightContainer>
        <AIContainer>
          <PageText>Ask a Question!</PageText>
          <Input
            placeholder="Ask a question about solar eclipses"
            value={question}
            onChange={handleQuestionChange}
          />
          <Button onClick={handleQuestionSubmit}>
            {loading ? "Loading..." : "Submit"}
          </Button>

          <AnswerContainer>{answer}</AnswerContainer>
          {sampleQuestions.map((q, index) => (
            <SampleQButton
              key={index}
              onClick={() => {
                handleQuestionChange({ target: { value: q } });
              }}
            >
              {q}
            </SampleQButton>
          ))}
        </AIContainer>
      </RightContainer>
    </Container>
  );
}
export default LookUp;

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 12px;
  padding: 20px 40px;
  align-items: center;
  gap: 40px;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
`;

const Button = styled.button`
  padding: 8px 16px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
`;

const PageImage = styled.img`
  max-width: 640px;
`;

const PageText = styled.p`
  font-size: 24px;
  line-height: 1.5;
  max-width: 720px;
  margin: 0 auto;
  text-align: center;

  .highlight {
    cursor: pointer;
  }

  .highlight:hover {
    background-color: yellow;
  }

  .tooltip {
    position: relative;
  }

  .tooltip::before {
    content: attr(data-tooltip);
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    background-color: gray;
    color: white;
    padding: 0px 4px;
  }

  .tooltip:hover::before {
    visibility: visible;
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 640px;
`;

const VideoIconContainer = styled.div`
  position: absolute;
  top: 60px;
  right: 340px;
  cursor: pointer;
`;

const QuestionIconContainer = styled.div`
  position: absolute;
  top: 110px;
  right: 50px;
  cursor: pointer;
`;

const ImageIconContainer = styled.div`
  position: absolute;
  top: 80px;
  right: 320px;
  cursor: pointer;
`;

const AnswerContainer = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  height: 240px;
  overflow-y: auto;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AIContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

const SampleQButton = styled.button`
  padding: 8px 14px;
  font-size: 16px;
  font-weight: bold;
  background-color: #6d6d6d;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
`;
