import "./jason.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./Pages/Home.js";
import LookUp from "./Pages/LookUp.js";
// import Chase from "./Pages/Chase.js";
import Header from "./Components/Header";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route exact path="home" element={<Home />} />
            {/* <Route exact path="chase" element={<Chase />} /> */}
            <Route exact path="lookup" element={<LookUp />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
