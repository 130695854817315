import "../jason.css";
import styled from "@emotion/styled";
import React, { useState } from "react";

import Header from "../Components/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faTwitter,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function Home() {
  const ExpandableSection = ({ header, children }) => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
      setVisible(!visible);
    };

    return (
      <>
        <h4
          style={{ cursor: "pointer", margin: "24px 0px 10px 0px" }}
          onClick={toggleVisibility}
        >
          {header}{" "}
          {visible ? (
            <FontAwesomeIcon icon={faMinus} size="m" />
          ) : (
            <FontAwesomeIcon icon={faPlus} size="m" />
          )}
        </h4>
        {visible && children}
      </>
    );
  };

  return (
    <div>
      <Header />
      <div style={{ textAlign: "center" }}>
        <h1 style={{ marginBottom: "20px" }}>Jason Kuperberg</h1>

        <SocialIconLink
          href="https://www.linkedin.com/in/jasonkuperberg"
          target="_blank"
          rel="noopener noreferrer"
          alt="Jason Kuperberg LinkedIn"
        >
          <FontAwesomeIcon icon={faLinkedin} size="xl" />
        </SocialIconLink>
        <SocialIconLink
          href="https://www.twitter.com/jasonkuperberg"
          target="_blank"
          rel="noopener noreferrer"
          alt="Jason Kuperberg Twitter"
        >
          <FontAwesomeIcon icon={faTwitter} size="xl" />
        </SocialIconLink>
        <SocialIconLink
          href="https://www.instagram.com/jasonkuperberg"
          target="_blank"
          rel="noopener noreferrer"
          alt="Jason Kuperberg Instagram"
        >
          <FontAwesomeIcon icon={faInstagram} size="xl" />
        </SocialIconLink>
        <SocialIconLink
          href="https://www.tiktok.com/@jasonkuperberg"
          target="_blank"
          rel="noopener noreferrer"
          alt="Jason Kuperberg TikTok"
        >
          <FontAwesomeIcon icon={faTiktok} size="xl" />
        </SocialIconLink>
        <SocialIconLink
          href="mailto:jason@othersideai.com"
          alt="Jason Kuperberg Email"
        >
          <FontAwesomeIcon icon={faEnvelope} size="xl" />
        </SocialIconLink>

        <h3>New York, NY</h3>
      </div>
      <div
        style={{
          maxWidth: "800px",
          margin: "12px auto 10px auto",
        }}
      >
        <p>
          Hello! I'm Jason, a startup builder focused on consumer technology and
          artificial intelligence. I'm passionate about building products that
          enhance human creativity and productivity.
        </p>
        <p>
          I'm the co-founder of OthersideAI. We are the creators of{" "}
          <a
            href="https://www.hyperwriteai.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            HyperWrite
          </a>
          , a leading AI writing platform with over a million users. I was named
          to the{" "}
          <a
            href="https://www.forbes.com/sites/rashishrivastava/2023/11/28/under-30-2024-consumer-technology-these-founders-are-developing-ai-code-design-invest-for-you/?sh=6fce342495b5"
            target="_blank"
            rel="noopener noreferrer"
          >
            Forbes 30 Under 30
          </a>{" "}
          list for Consumer Technology.
        </p>
        <p>
          Our team is currently building{" "}
          <a
            href="https://www.hyperwriteai.com/personal-assistant"
            target="_blank"
            rel="noopener noreferrer"
          >
            Personal Assistant
          </a>
          , the first{" "}
          <a
            href="https://venturebeat.com/ai/hyperwrite-unveils-breakthrough-ai-agent-that-can-surf-the-web-like-a-human/"
            target="_blank"
            rel="noopener noreferrer"
          >
            AI agent
          </a>{" "}
          that can operate your browser to navigate the web and complete tasks
          like a human, as well as models and{" "}
          <a
            href="https://venturebeat.com/ai/the-self-operating-computer-emerges/"
            target="_blank"
            rel="noopener noreferrer"
          >
            frameworks
          </a>{" "}
          for AI powered web actions.
        </p>
        <p>
          When I'm not working, you can find me on a{" "}
          <a
            href="https://www.nytimes.com/2023/10/21/style/marathon-training-relationships.html#:~:text=Jason%20Kuperberg%2C%2027,hours%2C%E2%80%9D%20he%20said."
            target="_blank"
            rel="noopener noreferrer"
          >
            run
          </a>{" "}
          in Central Park, or exploring new coffee shops on the Upper West Side
          while reading, writing, working on my latest side project, or
          retweeting my co-founder{" "}
          <a
            href="https://twitter.com/mattshumer_"
            target="_blank"
            rel="noopener noreferrer"
          >
            Matt Shumer
          </a>
          .
        </p>
        <p>
          I'm passionate about exploring new ideas and meeting new people, and
          I'd love to connect with you.
        </p>
        <p>
          For opportunities and collaborations, please email me at
          jason@othersideai.com or message me on{" "}
          <a
            href="https://www.linkedin.com/in/jasonkuperberg"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default Home;

const SocialIconLink = styled.a`
  margin: 0px 8px;
  color: #333333;
  &:hover {
    color: #666666;
  }
`;
